"use client";

import { get_news, type newsFilter } from "app/actions";
import { Button } from "components/Elements/Button/Button";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import NewsPagination from "./NewsPagination";
import { useRef } from "react";
import { useRouter, usePathname } from "next/navigation";

function PostGrid({ posts }: { posts: any[] }) {
  const first_post = posts[0];
  const remaining_posts = posts.slice(1, 10);

  if (!first_post) return null;

  return (
    <>
      <div className="flex flex-col w-[100%] mb-[50px]">
        <div className="h-[100%] w-[100%] relative flex flex-col lg:flex-row gap-[50px]">
          <div className="flex justify-start relative aspect-square overflow-hidden lg:overflow-visible">
            <img
              className="mx-auto w-[100%] h-[100%] lg:!max-w-[400px] lg:w-[400px] lg:h-[400px] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
              src={first_post.x_featured_media_original ?? ""}
              alt={first_post.media_alt ?? ""}
            />
          </div>
          <div className="flex flex-col justify-center items-start px-[0px]">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className="w-[100%] lg:w-[75%] text-[20px] lg:text-[26px] font-semibold pt-[10px]"
            >
              {first_post.title.rendered}
            </ReactMarkdown>
            <ReactMarkdown
              className="w-[100%] lg:w-[75%] text-[16px] pt-[10px]"
              rehypePlugins={[rehypeRaw]}
            >
              {first_post.excerpt.rendered.replace(/\[av.*?]/g, "")}
            </ReactMarkdown>
            <Button 
              url={`/news/${first_post.slug}`}
              classes="flex justify-start"
              styles={{}}
              innerClasses=""
              aria={{}}
              ref={null}
            >
              Read More
            </Button>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
        {remaining_posts.map((post) => (
          <article 
            key={post.id}
            className="overflow-hidden w-[100%] h-auto flex flex-col relative mb-[25px]"
          >
            <div className="flex flex-row justify-between mb-3">
              <p className="text-[14px] text-700">
                {new Intl.DateTimeFormat("en-gb").format(new Date(post.date))}
              </p>
              <ReactMarkdown
                className="font-bold text-[14px] text-700"
                rehypePlugins={[rehypeRaw]}
              >
                {post.x_categories.split(",")?.[0] ?? ""}
              </ReactMarkdown>
            </div>
            <div className="aspect-[4/3] relative overflow-hidden mb-3">
              <img
                src={post.x_featured_media_original}
                className="h-[100%] w-[100%] hover:scale-[1.1] hover:opacity-75 transition-all duration-300 object-cover"
                alt={post.media_alt ?? ""}
              />
            </div>
            <div className="flex flex-col flex-grow">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                className="text-[18px] font-semibold mb-2 line-clamp-2"
              >
                {post.title.rendered}
              </ReactMarkdown>
              <ReactMarkdown
                className="text-[14px] mb-3 line-clamp-2"
                rehypePlugins={[rehypeRaw]}
              >
                {post.excerpt.rendered.replace(/\[av.*?]/g, "")}
              </ReactMarkdown>
              <div className="mt-auto">
                <Button 
                  url={`/news/${post.slug}`}
                  classes="flex justify-start"
                  styles={{}}
                  innerClasses=""
                  aria={{}}
                  ref={null}
                >
                  Read More
                </Button>
              </div>
            </div>
          </article>
        ))}
      </div>
    </>
  );
}

function SearchPostResults({
  search,
  current_page,
  total_pages,
  posts,
}: {
  search: string;
  current_page: number;
  total_pages: number;
  posts: any[];
}) {
  const router = useRouter();
  const path = usePathname();
  const goto_page = useRef((page_num: number) => {
    const params = new URLSearchParams();
    params.set('search', search);
    if (page_num > 0) {
      params.set('p', page_num.toString());
    }
    router.push(`${path}?${params.toString()}`);
  });

  if (!posts.length) {
    return (
      <div className="w-[85%] mx-auto mt-8">
        <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
          No Posts Found
        </h3>
      </div>
    );
  }

  return (
    <div className="w-[85%] mx-auto mt-8">
      <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
        Post Results
      </h3>
      <PostGrid posts={posts} />
      <div className="w-full flex flex-row items-center justify-center mt-8">
        <NewsPagination
          current_page={current_page}
          pages={total_pages}
          goto_page={goto_page}
        />
      </div>
    </div>
  );
}

export default SearchPostResults; 