"use client";
import { get_news, type newsFilter } from "app/actions";
import { Button } from "components/Elements/Button/Button";
import Image from "next/image";
import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import NewsPagination from "./NewsPagination";
import NewsFilter from "./NewsFilter";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import qs from "qs";
type post_arr = Awaited<ReturnType<typeof get_news>>;
function update_router_filter(
  filter: newsFilter,
  page: number,
  router: ReturnType<typeof useRouter>,
  path: string,
) {
  const str = qs.stringify({ ...filter, p: page });
  router.replace(`${path}?${str}`);
}
function UpdatePather({
  ref,
}: {
  ref: RefObject<(filter: newsFilter, page: number) => void>;
}) {
  const path = usePathname();
  const router = useRouter();
  ref.current = (fil, page) => {
    console.log("request update");
    update_router_filter(fil, page, router, path);
  };
  return null;
}
export default function ClientNews({
  base_filter,
  header,
  base_posts,
  categories,
  tags,
  base_page = 0,
  headless = false,
  single = false,
  no_search = false,
}: {
  base_filter: newsFilter;
  header: string;
  base_posts: post_arr;
  base_page?: number;
  categories: Record<number, string>;
  tags: Record<number, string>;
  headless?: boolean;
  single?: boolean;
  no_search?: boolean;
}) {
  const [filter_new, set_filter] = useState(base_filter);
  const [posts, set_posts] = useState(base_posts.posts);
  const [pages, set_pages] = useState(base_posts.pages);
  const [current_page, set_current_page] = useState(base_page);
  const update_path = useRef((fil: newsFilter, page: number) => { });
  useEffect(() => {
    console.log("new base_posts");
    set_posts(base_posts.posts);
  }, [base_posts]);
  useEffect(() => {
    update_path.current(filter_new, current_page);
    (async () => {
      console.log("filter", filter_new, "current_page", current_page);
      const new_posts = await get_news(filter_new, current_page);

      set_posts([...new_posts.posts]);
      set_pages(new_posts.pages);
    })();
  }, [current_page, filter_new]);
  const first_post = posts?.[0];
  const goto_page = useRef((page_num: number) => {
    console.log("goto page", page_num);
    set_current_page(page_num);
  });
  const update_filter = useCallback(
    (fn: (old: newsFilter) => newsFilter) => {
      const updated_filter = fn(filter_new);
      console.log("pass old filter", filter_new, "got", updated_filter);
      set_filter(updated_filter);
      const filterKeys: (keyof newsFilter)[] = ['search', 'year', 'category', 'topic'];
      if (filterKeys.some(key => filter_new[key] !== updated_filter[key])) {
        set_current_page(0);
      }
    },
    [filter_new],
  );

  return (
    <RejectIfSearch enabled={no_search}>
      {!no_search && <UpdatePather ref={update_path} />}
      <div className="w-[85%] flex flex-col justify-start items-start mx-auto">
        {!headless && !single && (
          <NewsFilter
            update_filter={update_filter}
            categories={categories}
            tags={tags}
            filter={filter_new}
          />
        )}
        {!first_post ? (
          <div>no posts found</div>
        ) : (
          <div className="w-full">
            <h3 className="mt-[15px] mb-[40px] w-[100%] h-[auto] flex">
              {header}
            </h3>
            <FirstPost post={first_post} />
            <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-5">
              {posts.slice(1).map((post) => (
                <div
                  key={post.id}
                  className="articleTab overflow-hidden w-[100%]  h-auto flex  flex-col  relative mb-[50px] "
                >
                  <RenderPost post={post} />
                </div>
              ))}
            </div>
            <div className="w-full flex flex-row items-center justify-center">
              {!single ? (
                <NewsPagination
                  current_page={current_page}
                  pages={pages}
                  goto_page={goto_page}
                />
              ) : (
                <>
                  {/*@ts-ignore*/}
                  <Button url={`/news?category=${filter_new.category}`}>
                    View more
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </RejectIfSearch>
  );
}
function RenderPost({ post }: { post: post_arr["posts"][number] }) {
  const first_category = post.x_categories.split(",")?.[0];
  const intl = new Intl.DateTimeFormat("en-gb");
  return (
    <>
      <div className="flex flex-row justify-between">
        <p className="text-[14px] text-700">
          {intl.format(new Date(post.date))}
        </p>
        <ReactMarkdown
          className="font-bold text-[14px] text-700"
          rehypePlugins={[rehypeRaw]}
        >
          {first_category ?? ""}
        </ReactMarkdown>
      </div>
      <div className="aspect-square relative overflow-hidden">
        <img
          src={post.x_featured_media_original}
          className="h-[100%] w-[100%] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
          alt={post.media_alt ?? ""}
        />
      </div>
      <div className="lg:h-[250px] flex flex-col ">
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          className="mt-[20px] excerptP text-[20px] font-semibold pt-[10px]"
        >
          {post.title.rendered}
        </ReactMarkdown>
        <ReactMarkdown
          className="excerptP mt-[20px] w-[100%] text-[16px] pt-[10px]"
          rehypePlugins={[rehypeRaw]}
        >
          {removeCodeStartingWithAV(post?.excerpt?.rendered)}
        </ReactMarkdown>
        <div className="flex flex-col mt-auto w-fit">
          {/*@ts-ignore*/}
          <Button url={`/news/${post.slug}`}>Read More</Button>
        </div>
      </div>
    </>
  );
}
export function removeCodeStartingWithAV(inputString: string) {
  const regex = /\[av.*?]/g;
  return inputString.replace(regex, "");
}
function FirstPost({ post }: { post: post_arr["posts"][number] }) {
  return (
    <div className="flex flex-col w-[100%] articleTab mb-[50px] ">
      <div className="h-[100%] w-[100%] relative flex flex-col lg:flex-row gap-[50px]">
        <div className=" flex justify-start relative aspect-square overflow-hidden lg:overflow-visible ">
          <img
            className="mx-auto w-[100%] h-[100%] lg:!max-w-[400px] lg:w-[400px] lg:h-[400px] hover:scale-[1.1] hover:opacity-75 aspect-square object-cover mb-[50px]"
            src={post.x_featured_media_original ?? ""}
            alt={post.media_alt ?? ""}
          />
        </div>
        <div className="flex flex-col justify-center items-start px-[0px]">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className="w-[100%] lg:w-[75%] text-[20px] lg:text-[26px] font-semibold pt-[10px]"
          >
            {post.title.rendered}
          </ReactMarkdown>
          <ReactMarkdown
            className={" w-[100%] lg:w-[75%] text-[16px] pt-[10px]"}
            rehypePlugins={[rehypeRaw]}
          >
            {removeCodeStartingWithAV(post?.excerpt?.rendered)}
          </ReactMarkdown>
          {/*@ts-ignore*/}
          <Button url={`/news/${post.slug}`}>Read More</Button>
        </div>
      </div>
    </div>
  );
}
const forbidden = ["search", "topic", "category", "year"];
function RejectIfSearch({
  enabled,
  children,
}: { enabled: boolean; children: ReactNode }) {
  const search = useSearchParams();
  if (!enabled) return children;
  for (const term of forbidden) {
    if (search.has(term)) return null;
  }
  return children;
}
