"use client";

import { usePathname } from "next/navigation";
import { use, useRef } from "react";
import { CiSearch } from "react-icons/ci";

export default function SearchInput({
  search,
  onSearchAction,
}: {
  search: Promise<Record<string, string>>;
  onSearchAction: (path: string, new_query: string) => void;
}) {
  const search_params = use(search);
  const inputRef = useRef(null as unknown as HTMLInputElement);
  const pathname = usePathname();
  return (
    <div className=" gap-[25px] w-[100%] lg:w-[100%] flex flex-col justify-between lg:flex-row my-[50px] ">
      <div className="w-[100%]  flex py-[10px] flex-row bg-[#f7f7f7] rounded-full">
        <input
          ref={inputRef}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSearchAction(pathname, e.currentTarget.value);
            }
          }}
          id="searchBox"
          className="border-none w-[100%] text-[22px] bg-[#F7F7F7] rounded-full pl-[25px]"
          defaultValue={search_params.search}
          type="search"
          placeholder="Search..."
        />
        <button
          onClick={() => {
            onSearchAction(pathname, inputRef.current.value);
          }}
          type="button"
          className="pr-[26px]"
        >
          <CiSearch size={30} color={"#000"} />
        </button>
      </div>
    </div>
  );
}
