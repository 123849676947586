"use client";
import { type RefObject, useMemo } from "react";
import styles from "../components/Pagination/Pagination.module.css";

interface NewsPaginationProps {
  current_page: number;
  pages: number;
  goto_page: RefObject<(page_num: number) => void>;
}
export default function NewsPagination(props: NewsPaginationProps) {
  const paginationArr = Array.from({ length: props.pages }, (_, i) => i + 1);

  const displayedPages = useMemo(() => {
    const currentPageNum = Number(props.current_page) + 1;
    const pagesToShow = 5;

    if (props.pages <= pagesToShow) {
      return paginationArr;
    }

    let startPage = Math.max(currentPageNum - Math.floor(pagesToShow / 2), 1);
    const endPage = Math.min(startPage + pagesToShow - 1, props.pages);

    if (endPage - startPage + 1 < pagesToShow) {
      startPage = endPage - pagesToShow + 1;
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  }, [props.current_page, props.pages, paginationArr]);
  console.log("pagin", props);
  return (
    <>
      {props.current_page > 0 && (
        <div
          tabIndex={0}
          className={`pagination-item ${styles.item}`}
          onClick={() => {
            props.goto_page.current(0);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter" || event.keyCode === 13) {
              props.goto_page.current(0);
            }
          }}
        >
          First
        </div>
      )}
      {displayedPages.map((paginationItem) => {
        const active =
          paginationItem === props.current_page + 1 ? "activePag" : "";
        return (
          <div
            tabIndex={0}
            key={`pagination-${paginationItem}`}
            className={`pagination-item pagination-item--${paginationItem} ${styles.item} ${active}`}
            onClick={() => {
              props.goto_page.current(paginationItem - 1);
              window.scrollTo({ top: 0 });
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" || event.keyCode === 13) {
                props.goto_page.current(paginationItem - 1);
                window.scrollTo({ top: 0 });
              }
            }}
          >
            {paginationItem}
          </div>
        );
      })}
      {props.current_page + 1 < props.pages && (
        <div
          tabIndex={0}
          className={`pagination-item ${styles.item}`}
          onClick={() => {
            props.goto_page.current(props.pages - 1);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter" || event.keyCode === 13) {
              console.log("go to", props.pages);
              props.goto_page.current(props.pages - 1);
            }
          }}
        >
          Last
        </div>
      )}
    </>
  );
}
